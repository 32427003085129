<template>
  <v-menu bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on" v-if="isLogin">
        <v-avatar color="brown" size="48">
          <span class="white--text text-h5">{{ user.initials }}</span>
        </v-avatar>
      </v-btn>
      <v-btn outlined rounded v-else @click="authCheck">Login</v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <div v-if="isLogin">
            <v-avatar color="brown">
              <span class="white--text text-h5">{{ user.initials }}</span>
            </v-avatar>
            <h2>{{ user.fullName }}</h2>
            <h3 class="mt-1">
              {{ user.email }}
            </h3>
          </div>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-divider v-if="isLogin"></v-divider>
              <v-list-item @click="$emit(`getToken`, true)">
                <v-list-item-content> Get Token </v-list-item-content>
              </v-list-item>
              <v-list-item @click="jupyterhubClick(user.fullName)">
                <v-list-item-content> Jupyterhub Connect </v-list-item-content>
              </v-list-item>
              <!-- <v-divider v-if="isLogin"></v-divider>
              <v-list-item>
                <v-list-item-content> Edit Account </v-list-item-content>
              </v-list-item> -->
              <v-divider v-if="isLogin"></v-divider>
              <v-list-item @click="authCheck">
                <v-list-item-content>
                  {{ resolveLoginText }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <!-- <v-btn
            v-if="isLogin"
            depressed
            rounded
            text
            @click="$emit(`getToken`, true)"
          >
            Get Token</v-btn
          >
          <v-divider v-if="isLogin" class="my-3"></v-divider>
          <v-btn v-if="isLogin" depressed rounded text> Edit Account </v-btn>
          <v-divider v-if="isLogin" class="my-3"></v-divider>
          <v-btn depressed rounded text @click="authCheck">
            {{ resolveLoginText }}
          </v-btn> -->
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    user: {
      initials: String,
      fullName: String,
      email: String,
    },
  },
  data: () => ({}),
  computed: {
    resolveLoginText() {
      if (this.isLogin) return "Log out";
      return "Log in";
    },
    ...mapState({
      isLogin: (state) => state.isLogin,
    }),
  },
  methods: {
    jupyterhubClick(username) {
      const accessToken = localStorage.getItem("token");
      window.open(
          `https://vulture-relieved-magpie.ngrok-free.app/jupyterhub/connect?token=${accessToken}&username=${username}`
        );
    },
    authCheck() {
      if (!this.isLogin) {
        window.location.replace(
          `${process.env.VUE_APP_DOMAIN}/oauth/login/dsmauth/?callback=${window.location.origin}`
        );
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        this.$store.commit("SET_LOGOUT");
        window.location.replace(
          `${process.env.VUE_APP_DOMAIN}/authen/logout/?callback=${window.location.origin}`
        );
      }
    },
  },
};
</script>

<style></style>
